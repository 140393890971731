import { extendTailwindMerge } from 'tailwind-merge';

export const twMerge = extendTailwindMerge({
  extend: {
    classGroups: {
      'font-size': [
        {
          text: [
            'xxs-medium',
            'xs-regular',
            'xs-medium',
            'xs-bold',
            's-regular',
            's-medium',
            's-semibold',
            's-bold',
            'm-regular',
            'm-medium',
            'm-bold',
            'l-regular',
            'l-medium',
            'l-bold',
          ],
        },
      ],
    },
  },
});

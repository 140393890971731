import cx from 'classnames';
import { forwardRef } from 'react';

import { twMerge } from '~/utils/twMerge';

import { Loader } from './loaders/Loader';

// TODO: Extract colors from ButtonVariant to ButtonColor/Theme and move outline to variants

export type ButtonVariant =
  | 'legacy-primary'
  | 'legacy-muted'
  | 'legacy-neutral'
  | 'legacy-ghost'
  | 'legacy-link'
  | 'legacy-link-text'
  | 'primary-fill'
  | 'primary-outline'
  | 'primary-secondary'
  | 'ghost'
  | 'registration'
  | 'registration-yellow'
  | 'registration-outline'
  | 'alert-fill';
export type ButtonSize = 'xxs' | 'xs' | 'sm' | 'md' | 'lg' | 'xl';
type ButtonShape = 'none' | 'square' | 'circle';

export interface ButtonProps extends React.ComponentPropsWithoutRef<'button'> {
  type: React.ComponentPropsWithoutRef<'button'>['type']; // Make type explicit to avoid subtle errors
  loading?: boolean;
  variant?: ButtonVariant;
  size?: ButtonSize;
  shape?: ButtonShape;
  outline?: boolean;
  startIcon?: React.ReactNode;
  endIcon?: React.ReactNode;
}

export const Button = forwardRef<HTMLButtonElement, ButtonProps>(function ButtonWithoutRef(
  {
    size = 'md',
    variant = 'legacy-primary',
    shape = 'none',
    outline,
    className,
    loading,
    disabled,
    children,
    startIcon,
    endIcon,
    ...rest
  },
  ref,
) {
  const isLinkText = variant === 'legacy-link-text';
  const isLegacyVariant = variant.startsWith('legacy-');

  return (
    <button
      ref={ref}
      className={cx(
        getButtonClassNames({
          size,
          variant,
          shape,
          isLegacy: isLegacyVariant,
          isLinkText,
        }),
        {
          'btn-outline': isLegacyVariant && outline,
        },
        className,
      )}
      disabled={loading || disabled}
      {...rest}
    >
      {loading ? <Loader variant="inherit" /> : null}
      {startIcon ? startIcon : null}
      {children}
      {endIcon ? endIcon : null}
    </button>
  );
});

const classesByVariant: Record<ButtonVariant, string | string[]> = {
  'primary-fill': [
    'bg-primary border border-transparent outline-2 outline-primary/10 outline-offset-1 text-s-medium text-white whitespace-nowrap',
    'hocus:enabled:bg-primary-600 focus:enabled:outline',
    'aria-expanded:bg-primary-600 aria-expanded:outline',
    'disabled:bg-gray-500 disabled:cursor-not-allowed',
  ],
  'primary-outline': [
    'border-gradient text-s-medium outline-2 outline-primary/10 outline-offset-1 whitespace-nowrap',
    'hocus:enabled:before:hidden focus:enabled:outline hocus:enabled:border-primary hover:border-primary hover:before:hidden',
    'aria-expanded:before:hidden aria-expanded:outline aria-expanded:border-primary',
    'disabled:text-gray-500 disabled:cursor-not-allowed disabled:hocus:border-transparent disabled:hocus:border-gradient disabled:hocus:before:block',
  ],
  registration: [
    'bg-brand-ram-green border border-transparent outline-2 outline-brand-ram-green/10 outline-offset-1 text-s-semibold text-white whitespace-nowrap',
    'hocus:enabled:bg-brand-ram-green focus:enabled:outline',
    'aria-expanded:bg-brand-ram-green aria-expanded:outline',
    'disabled:bg-gray-500 disabled:cursor-not-allowed',
  ],
  'registration-yellow': [
    'bg-brand-ram-yellow border border-transparent outline-2 outline-brand-ram-yellow/10 outline-offset-1 text-s-semibold text-black whitespace-nowrap',
    'hocus:enabled:bg-brand-ram-yellow focus:enabled:outline',
    'aria-expanded:bg-brand-ram-yellow aria-expanded:outline',
    'disabled:bg-gray-500 disabled:cursor-not-allowed',
  ],
  'registration-outline': [
    'border-gradient text-s-medium outline-2 outline-light-gray/10 outline-offset-1 whitespace-nowrap shadow-drop-shadow',
    'hocus:enabled:before:hidden focus:enabled:outline hocus:enabled:border-brown-500 hover:border-brown-500 hover:before:hidden',
    'aria-expanded:before:hidden aria-expanded:outline aria-expanded:border-brown-500',
    'disabled:text-gray-500 disabled:cursor-not-allowed disabled:hocus:border-transparent disabled:hocus:border-gradient disabled:hocus:before:block',
  ],
  ghost: ['text-s-medium border border-transparent hocus:outline-none'],
  'primary-secondary': [
    'border border-gray-200 text-primary text-s-medium outline-2 outline-primary/10 outline-offset-1 whitespace-nowrap',
    'hocus:enabled:before:hidden focus:enabled:outline hocus:enabled:border-primary',
    'aria-expanded:before:hidden aria-expanded:outline aria-expanded:border-primary',
    'disabled:text-gray-500 disabled:cursor-not-allowed',
  ],
  'alert-fill': [
    'bg-red-500 border border-transparent outline-2 outline-red-500/10 outline-offset-1 text-s-medium text-white whitespace-nowrap',
    'hocus:enabled:bg-red-600 focus:enabled:outline',
    'aria-expanded:bg-red-600 aria-expanded:outline',
    'disabled:bg-gray-500 disabled:cursor-not-allowed',
  ],
  'legacy-primary': 'btn-primary',
  'legacy-neutral': 'btn-neutral',
  'legacy-muted': '',
  'legacy-ghost': 'btn-ghost',
  'legacy-link': 'btn-link no-underline hocus:underline font-normal normal-case',
  'legacy-link-text': 'link link-hover link-primary',
};

const legacyClassesBySize: Record<ButtonSize, string> = {
  xxs: 'btn-xxs',
  xs: 'btn-xs',
  sm: 'btn-sm',
  md: 'btn-md',
  lg: 'btn-lg',
  xl: 'btn-xl',
};

const classesBySize: Record<ButtonSize, string> = {
  xxs: 'px-1.5 py-[.1875rem] rounded-md [--border-gradient-radius:0.375rem] leading-[1.125rem]', // 20px
  xs: 'px-1.5 py-px rounded-md [--border-gradient-radius:0.375rem]', // 24px
  sm: 'px-2 py-1 rounded-md [--border-gradient-radius:0.375rem]', //30
  md: 'px-2 py-[0.3125rem] rounded-lg [--border-gradient-radius:0.5rem]', // 32px
  lg: 'px-2 py-[0.4375rem] rounded-lg [--border-gradient-radius:0.5rem]', // 36px
  xl: 'px-2.5 py-[0.6875rem] rounded-lg [--border-gradient-radius:0.5rem]', // 44px
};

const legacyClassesByShape: Record<ButtonShape, string> = {
  none: '',
  square: 'btn-square',
  circle: 'btn-circle',
};

const classesByShape: Record<ButtonShape, string> = {
  none: '',
  square: '',
  circle: 'rounded-full',
};

const squareLikeClassesOverridesBySize: Record<ButtonSize, string> = {
  xxs: 'p-[.1875rem]',
  xs: 'p-px',
  sm: 'p-1',
  md: 'p-[0.3125rem]',
  lg: 'p-[0.4375rem]',
  xl: 'p-[0.6875rem]',
};

export const classesByShapeAndSize: Partial<Record<ButtonShape, Record<ButtonSize, string>>> = {
  square: squareLikeClassesOverridesBySize,
  circle: squareLikeClassesOverridesBySize,
};

const classesByVariantAndSize: Partial<Record<ButtonVariant, Record<ButtonSize, string>>> = {
  'primary-fill': {
    xxs: '',
    xs: '',
    sm: '',
    md: 'text-s-semibold',
    lg: 'text-s-semibold',
    xl: 'text-s-semibold',
  },
  'primary-secondary': {
    xxs: '',
    xs: '',
    sm: '',
    md: 'text-s-semibold',
    lg: 'text-s-semibold',
    xl: 'text-s-semibold',
  },
};

export function getButtonClassNames({
  variant,
  size,
  shape,
  isLegacy,
  isLinkText,
}: {
  variant: ButtonVariant;
  size: ButtonSize;
  shape: ButtonShape;
  isLegacy: boolean;
  isLinkText?: boolean;
}) {
  if (isLegacy) {
    if (isLinkText) {
      return classesByVariant[variant];
    }

    return twMerge(
      'btn flex-nowrap',
      classesByVariant[variant],
      legacyClassesBySize[size],
      legacyClassesByShape[shape],
    );
  }

  return twMerge(
    'inline-flex items-center gap-1 text-center justify-center',
    classesByVariant[variant],
    classesBySize[size],
    classesByVariantAndSize[variant]?.[size],
    classesByShape[shape],
    classesByShapeAndSize[shape]?.[size],
  );
}
